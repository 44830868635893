import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ForgotPassword from './pages/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/forgotPassword' component={ForgotPassword} />
        <Route exact path='/verifyEmail' component={VerifyEmail} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;
