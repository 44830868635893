import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #3D3D3D;
`;

export const Box = styled.div`
  background: #ffffff;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 350px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  h2 {
    margin-top: 5%;}
`;
