import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }
    body {
        background: #161717;
        color: #FFF;
        -webkit-font-smoothing: antialiased;
    }
    body, input, button {
        font-family: "Roboto";
        font-size: 18px;
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
    }
    strong {
        font-weight: 500;
    }
    button {
        cursor: pointer;
    }
`;
