import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { Container, Box, Button, Input } from "./styles";
import api from "../../services/api";
import { MDBRow, MDBCol} from "mdb-react-ui-kit";

const ForgotPassword = () => {
  //Ler as senhas, ver se sao iguais e mandar pro back junto com o token da url

  const [token, setToken] = useState();

  useEffect(() => {
    const idAssinaturaAtualAux = window.location.search;
    const idAssinaturaAtualNew = new URLSearchParams(idAssinaturaAtualAux);
    setToken(idAssinaturaAtualNew.get("token"));
  }, [token]);

  const [inputs, setInputs] = useState({});
  const [validacao, setValidacao] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async event => {
    event.preventDefault();

    const { senha, confirmacao } = inputs;

    const schema = Yup.object().shape({
      senha: Yup.string().required("Senha obrigatória"),
      confirmacao: Yup.string().oneOf(
        [Yup.ref("senha"), null],
        "As senhas devem ser iguais",
      ),
    });

    await schema
      .isValid({ senha, confirmacao })
      .then((valid) => {
        setValidacao(valid);
        if (validacao === false) {
          toast.warning("As senhas devem ser iguais!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });

    console.log("Senha:", senha);
    console.log("Token:", token);
    console.log("Validação:", validacao);

    await api.patch(`/usuario/resetPassword/${token}`, { senha })
      .then(() => {
        toast.success("Senha alterada com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Erro ao resetar senha! Tente novamente!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <Container>
      <ToastContainer />
      <Box>
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol size="12">
              <h2><strong>Restauração de senha</strong></h2>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <label>Nova senha</label>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <Input>
                <input
                  type="password"
                  name="senha"
                  value={inputs.senha || ""}
                  onChange={handleChange}
                />
              </Input>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <label>Confirmar senha</label>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <Input>
                <input
                  type="password"
                  name="confirmacao"
                  value={inputs.confirmacao || ""}
                  onChange={handleChange}
                />
              </Input>
            </MDBCol>
          </MDBRow>
          <Button>
            <button type="submit">
              Enviar
            </button>
          </Button>
        </form>
      </Box>
    </Container>
  )
}

export default ForgotPassword;
