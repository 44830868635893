import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #3D3D3D;
`;

export const Button = styled.div`
  button {
    margin-top: 5%;
    background: #D32F2F;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(211, 47, 47, 0.24);
    color: #ffffff;
    border: 0;
    height: 50px;
    width: 100%;

    &:hover {
      background: ${shade(0.2, "#D32F2F")};
    }
  }
`;

export const Box = styled.div`
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 350px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  h2 {
    margin-bottom: 15%;
  }

  label {
    margin-bottom: 10%;
    text-align: left;
  }
`;

export const Input = styled.div`
  input {
    border: 0;
  }

  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #B7B7B7;
  transition: .3s border-color;
  margin-bottom: 10%;

  &:hover {
    border: 1px solid ${shade(0.2, "#B7B7B7")};
  }
`;
