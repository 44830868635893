import React, { useEffect } from "react";
import { Container, Box } from "./styles";
import api from "../../services/api";
import ImgEmail from "../assets/Email.png"
import { MDBRow, MDBCol} from "mdb-react-ui-kit";

const VerifyEmail = () => {

  useEffect(() => {
    const idAssinaturaAtualAux = window.location.search;
    const idAssinaturaAtualNew = new URLSearchParams(idAssinaturaAtualAux);
    const code = idAssinaturaAtualNew.get("token");

    console.log("Code: ", code)

    api.post(`/email/verify/${code}`)
  });

  return (
    <Container>
      <Box>
        <form>
          <MDBRow>
            <MDBCol size="12">
              <img src={ImgEmail} alt="Minha Figura" />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <h2 style={{ color: "#89CF66" }}><strong>Tudo pronto</strong></h2>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <h2><strong>Email confirmado com sucesso!</strong></h2>
            </MDBCol>
          </MDBRow>
        </form>
      </Box>
    </Container>
  );
}

export default VerifyEmail;
